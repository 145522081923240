// @flow
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Typography,
  Grid
} from '@material-ui/core'
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { useLocation, useParams, useHistory } from 'react-router-dom'

import {
  TextField,
  Button,
  Snackbar,
  FilePreview,
  FileUpload
} from 'components'
import { clientScheme } from 'schemas'
import { usePost, useGet, useFileUpload } from 'hooks'
import { formatDate } from 'utils'

const { initialValues, schema } = clientScheme

function Profile() {
  const classes = useStyles()
  const [data, setData] = useState(null)
  const { state, pathname } = useLocation()
  const { id } = useParams()
  const history = useHistory()
  const [isEditable, setIsEditable] = useState(false)
  const { loading: getLoading, error: getError, data: getData } = useGet(
    state ? null : `client/${id}`
  )
  const {
    upload: uploadFront,
    loading: uploadFrontLoading,
    error: uploadFrontError
  } = useFileUpload()
  const {
    upload: uploadBack,
    loading: uploadBackLoading,
    error: uploadBackError
  } = useFileUpload()

  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  useEffect(() => {
    if (!state) {
      return
    }

    const stateCopy = { ...state }

    if (stateCopy && stateCopy.tableData) {
      delete stateCopy.tableData
    }

    setData(stateCopy)
  }, [state])

  useEffect(() => {
    if (!getData) {
      return
    }

    history.replace({ pathname, state: getData })
  }, [getData, history, pathname])

  if ((getLoading || (!getData && !data) || (getData && !data)) && !getError) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (getError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {getError}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe un cliente con el id ${id}`} />
      </Card>
    )
  }

  return (
    <>
      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadFrontError)}
        type='error'
        message={
          typeof uploadFrontError === 'string'
            ? uploadFrontError
            : uploadFrontError && uploadFrontError.message
        }
      />

      <Snackbar
        isVisible={Boolean(uploadBackError)}
        type='error'
        message={
          typeof uploadBackError === 'string'
            ? uploadBackError
            : uploadBackError && uploadBackError.message
        }
      />

      <Formik
        initialValues={{ ...initialValues, ...data }}
        validationSchema={schema}
        onSubmit={(values: Object) =>
          post('client/update', values, (client: Object) => {
            history.replace({ pathname, state: client })
            setIsEditable(false)
          })
        }
      >
        {({
          errors,
          touched,
          resetForm,
          values
        }: {
          errors: Object,
          touched: Object,
          resetForm: Function,
          values: Object
        }) => {
          return (
            <Form>
              <Card>
                <CardHeader
                  title='Cliente'
                  action={
                    isEditable ? (
                      <Button
                        type='button'
                        fullWidth={false}
                        disabled={postLoading}
                        onClick={() => {
                          resetForm({ ...initialValues, ...data })
                          setIsEditable(false)
                        }}
                      >
                        <CloseIcon className={classes.icon} />
                        Cerrar
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        fullWidth={false}
                        onClick={() => setIsEditable(true)}
                      >
                        <EditIcon className={classes.icon} />
                        Editar
                      </Button>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='id'
                        as={TextField}
                        label='Id'
                        readOnly
                        disabled={isEditable}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='idNumber'
                        as={TextField}
                        label='Número de cédula'
                        readOnly={!isEditable}
                        error={Boolean(touched.idNumber && errors.idNumber)}
                        helperText={touched.idNumber && errors.idNumber}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field
                        name='name'
                        as={TextField}
                        label='Nombre'
                        readOnly={!isEditable}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='phoneNumber'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Número de teléfono'
                        type='tel'
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='address'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Dirección'
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='email'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Correo'
                        type='email'
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='description'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Descripción'
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Field
                        name='workplace'
                        as={TextField}
                        readOnly={!isEditable}
                        label='Lugar de trabajo'
                        error={Boolean(touched.workplace && errors.workplace)}
                        helperText={touched.workplace && errors.workplace}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <Field
                        name='createdAt'
                        as={TextField}
                        label='Fecha de creación'
                        value={formatDate(values.createdAt)}
                        readOnly
                        disabled={isEditable}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Field name='idFrontPhoto'>
                        {({
                          field: { name, value },
                          form: { setFieldValue }
                        }: {
                          field: Object,
                          form: Object
                        }) => {
                          if (!value && !isEditable) {
                            return null
                          }

                          if (!isEditable) {
                            return <FilePreview value={value} />
                          }

                          return (
                            <FileUpload
                              name={name}
                              label='Foto del frente de la cédula'
                              loading={uploadFrontLoading}
                              value={value}
                              onChange={async ({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }: {
                                target: Object
                              }) => {
                                try {
                                  if (validity.valid && file) {
                                    setFieldValue('idFrontPhoto', '')

                                    const { name, type } = file
                                    const newName = `${Date.now().toString()}${name}`

                                    const fileCopy = new File([file], newName, {
                                      type
                                    })

                                    await uploadFront({
                                      name: fileCopy.name,
                                      type: fileCopy.type,
                                      file: fileCopy,
                                      throwError: true
                                    })

                                    setFieldValue('idFrontPhoto', newName)
                                  }
                                } catch (error) {
                                  setFieldValue('idFrontPhoto', '')
                                }
                              }}
                            />
                          )
                        }}
                      </Field>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Field name='idBackPhoto'>
                        {({
                          field: { name, value },
                          form: { setFieldValue }
                        }: {
                          field: Object,
                          form: Object
                        }) => {
                          if (!value && !isEditable) {
                            return null
                          }

                          if (!isEditable) {
                            return <FilePreview value={value} />
                          }

                          return (
                            <FileUpload
                              name={name}
                              label='Foto de atrás de la cédula'
                              loading={uploadBackLoading}
                              value={value}
                              onChange={async ({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }: {
                                target: Object
                              }) => {
                                try {
                                  if (validity.valid && file) {
                                    setFieldValue('idBackPhoto', '')

                                    const { name, type } = file
                                    const newName = `${Date.now().toString()}${name}`

                                    const fileCopy = new File([file], newName, {
                                      type
                                    })

                                    await uploadBack({
                                      name: fileCopy.name,
                                      type: fileCopy.type,
                                      file: fileCopy,
                                      throwError: true
                                    })

                                    setFieldValue('idBackPhoto', newName)
                                  }
                                } catch (error) {
                                  setFieldValue('idBackPhoto', '')
                                }
                              }}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </CardContent>
                {isEditable && (
                  <CardActions>
                    <Button disabled={postLoading} fullWidth={false}>
                      <SaveIcon className={classes.icon} />
                      Guardar
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const useStyles = makeStyles((theme: Object) => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  }
}))

export default Profile
